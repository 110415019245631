<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">选项组管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="选项组名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em">选项组名称:</span>
              <el-input
                  v-model="groupName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入选项组名称"
              />
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="add()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                stripe
                :header-cell-style="tableHeader"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
              />
              <el-table-column
                  label="选项组名称"
                  align="left"
                  show-overflow-tooltip
                  prop="groupName"
                  min-width="160"
              />
              <el-table-column
                  label="选项数量"
                  align="center"
                  show-overflow-tooltip
                  prop="optionNum"
                  min-width="100"
              />
              <el-table-column
                  label="选项内容"
                  align="left"
                  show-overflow-tooltip
                  prop="optionNames"
                  min-width="160"
              />
              <el-table-column
                  label="状态"
                  align="center"
                  show-overflow-tooltip
                  prop="recommend"
                  min-width="100"
              >
                <template slot-scope="{ row }">
                  <el-switch
                      v-model="row.enabled"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="true"
                      :inactive-value="false"
                      @change="onStatusChange(row)">
                  </el-switch>
                  <span :style="{'color':row.enabled?'#13ce66':'#ff4949','margin-left': '10px'}">{{row.enabled?'使用':'禁用'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      style="padding: 0px 5px"
                      type="text"
                      size="mini"
                      @click="getsingle(scope.row)"
                  >编辑</el-button
                  >
                  <el-button
                      style="padding: 0px 5px"
                      type="text"
                      size="mini"
                      @click="onDelete(scope.row)"
                  >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
    <!-- 新增/编辑  弹窗 -->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="centerDialogVisible"
        width="60%"
        top="0.5%"
        center
        class="relation_jg_dialog"
        :close-on-click-modal="false"
        @close="doClose"
    >
<!--      <el-form-->
<!--          :model="ruleForm"-->
<!--          :rules="rules"-->
<!--          ref="ruleForm"-->
<!--          label-width="8rem"-->
<!--          class="demo-ruleForm"-->
<!--          style="width: 90%"-->
<!--      >-->
<!--        <el-form-item label="选项组名称：" prop="groupName">-->
<!--          <el-input-->
<!--              v-model="ruleForm.groupName"-->
<!--              size="small"-->
<!--              placeholder="请输入选项组名称"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
      <el-form ref="form" :model="form" label-width="120px" :rules="rules" >
<!--        <el-form-item label="题目类型" required class="el-form-questionType">-->
<!--          <el-radio-group-->
<!--              v-model="form.questionType"-->
<!--              @change="formTypeChange"-->
<!--          >-->
<!--            <el-radio :label="10">单选题</el-radio>-->
<!--            <el-radio :label="20">多选题</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="题目内容" class="areaBox" required>-->
<!--          <div-->
<!--              ref="editorsquestionTitle"-->
<!--              style="width: 100%; position: relative; z-index: 2"-->
<!--          />-->
<!--          <span v-show="showquestionTitle" class="showtitle"-->
<!--          >请输入题目内容</span-->
<!--          >-->
<!--        </el-form-item>-->
        <el-form-item label="选项组名称：" prop="groupName">
          <el-input
              v-model="form.groupName"
              size="small"
              placeholder="请输入选项组名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
            label="选项信息："
            prop="questionAnswer"
        >
          <div style="position:relative;">
            <span data-v-68361204="" style="color: red; position: absolute; top: 0px; left: -94px;">*</span>
            <el-radio-group v-model="form.questionAnswer">
              <div
                  v-for="(item, index) in form.optionList"
                  :key="index"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                  "
              >
                <el-form-item :prop="'optionList[' + index + '].optionName'">
                  选项{{ optionFM(index) }}&nbsp;&nbsp;
                  <el-input
                      v-model="form.optionList[index].optionName"
                      style="width: 220px"
                      maxlength="255"
                      show-word-limit
                  ></el-input>
                  <el-upload
                      :on-change="
                        (res, file) => {
                          return handleAvatarSuccess2(res, file, index);
                        }
                      "
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                        :src="
                          form.optionList[index].optionImgUrl ||
                          require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                    ></el-image>
                    <span style="line-height: 14px;" v-if="!form.optionList[index].optionImgUrl">上传图片</span>
                  </el-upload>
                  <span>&nbsp;分值：</span>
                  <el-input-number v-model="form.optionList[index].optionScore" :controls="false" :min="1" :max="999999" step="1" precision="0" style="width:80px"></el-input-number>
                  <el-button
                      v-if="form.optionList.length>2"
                      class="delet"
                      type="text"
                      @click="delOpenList(index)"
                  >删除选项</el-button
                  >
                </el-form-item>
              </div>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item
            label
            v-if="
              form.optionList.length < 26
            "
        >
          <el-button @click="addFromOpenList">添加选项</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="addOptions">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import E from "wangeditor";
import UploadPic from "@/mixins/UploadPic";
import { resetKeepAlive,validateURL } from "@/utils/common";
let editor = {};
export default {
  name: "policyExpressList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List,UploadPic],
  data() {
    return {
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      groupName: "", //问卷名称


      centerDialogVisible: false,
      dialogTitle: "", // 弹框的标题
      // ruleForm: {
      //   optionGroupId: "", // 选项组Id
      //   groupName: "", // 选项组名称
      // },

      form: {
        optionGroupId:'',
        groupName:'',
        optionList: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
        optionListall: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
      },

      rules: {
        groupName: [{ required: true, message: "请输入选项组名称", trigger: "blur" }],
      },
    };
  },
  computed: {},
  mounted() {

  },
  created() {
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    // 新增
    add() {
      this.centerDialogVisible = true;
      this.dialogTitle = "新增选项组";
    },
    // 修改
    // 获取单个数据
    getsingle(row) {
      this.$post("/biz/hr/evaluate/option-group/getInfo", {optionGroupId: row.optionGroupId }, 3000, true, 6)
          .then((ret) => {
            console.log(ret.data)
            this.centerDialogVisible = true;
            this.dialogTitle = "编辑选项组";
            const formData = ret.data;
            this.form.groupName = formData.groupName;
            this.form.optionGroupId = formData.optionGroupId;
            formData.options.forEach((e,i)=>{
              if(!e.optionImgUrl){
                e.optionImgUrl = ''
              }
            })
            // if (Number(formData.questionType) == 10) {
              this.form.optionList = formData.options;
            // } else if (Number(formData.questionType) == 20) {
            //   this.form.optionListall = formData.options;
            // }
          })
          .catch((err) => {
            return
          });
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      } else if (msg == 6) {
        return "G";
      } else if (msg == 7) {
        return "H";
      } else if (msg == 8) {
        return "I";
      } else if (msg == 9) {
        return "J";
      } else if (msg == 10) {
        return "K";
      } else if (msg == 11) {
        return "L";
      } else if (msg == 12) {
        return "M";
      } else if (msg == 13) {
        return "N";
      } else if (msg == 14) {
        return "O";
      } else if (msg == 15) {
        return "P";
      } else if (msg == 16) {
        return "Q";
      } else if (msg == 17) {
        return "R";
      } else if (msg == 18) {
        return "S";
      } else if (msg == 19) {
        return "T";
      } else if (msg == 20) {
        return "U";
      } else if (msg == 21) {
        return "V";
      } else if (msg == 22) {
        return "W";
      } else if (msg == 23) {
        return "X";
      } else if (msg == 24) {
        return "Y";
      } else if (msg == 25) {
        return "Z";
      }
    },
    addOptions() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (editors.txt.html() == "") {
          //   this.showquestionTitle = true;
          //   return false;
          // } else {
          //   this.showquestionTitle = false;
            const that = this;
            let parmar = {
              // paperId: that.paperId,
              // questionOrigin: that.form.questionOrigin,
              groupName: that.form.groupName,
              options: []
            };
            let qbQuestionOptions = [];
            let hasNull = false
              for (var i = 0; i < that.form.optionList.length; i++) {
                if(!that.form.optionList[i].optionName&&!that.form.optionList[i].optionImgUrl&&!hasNull){
                  this.$message.error("选项内容和选项图片至少填写一个,请补全第"+(i+1)+"个选项");
                  hasNull = true
                  return false
                }
                if(!that.form.optionList[i].optionScore&&!hasNull){
                  this.$message.error("请填写第"+(i+1)+"个选项分数");
                  hasNull = true
                  return false
                }
                qbQuestionOptions.push({
                  optionName: that.form.optionList[i].optionName,
                  optionImgUrl: that.form.optionList[i].optionImgUrl,
                  optionImg: that.form.optionList[i].optionImg,
                  optionScore: that.form.optionList[i].optionScore,
                  optionOrder: i
                });
              }
              parmar.options = qbQuestionOptions;
            console.log(parmar)
            let url = '/biz/hr/evaluate/option-group/insert'
            if(this.form.optionGroupId){
              parmar.optionGroupId = this.form.optionGroupId
              url = '/biz/hr/evaluate/option-group/modify'
            }
            if(!hasNull){
              that
                  .$post(url, parmar, 3000, true, 6)
                  .then((ret) => {
                    if (ret.status == "0") {
                      this.$message({
                        message: ret.message,
                        type: "success",
                      });
                    }
                    that.centerDialogVisible = false;
                    that.empty();
                    that.getData()
                    // that.$emit("getnewdatap");
                    // that.$emit("subShows", true);
                    if (ret.status == "-1") {
                      this.$message.error(ret.message);
                    }
                  })
                  .catch((err) => {
                    return;
                  });
            }

          // }
        }
      });
    },
    delOpenList(index) {
      // if (this.form.questionType == 10) {
        this.form.optionList.splice(index, 1);
      // } else if (this.form.questionType == 20) {
      //   this.form.optionListall.splice(index, 1);
      // }
    },
    addFromOpenList() {
      let data = {
        optionName: "",
        optionImgUrl: "",
        optionImg: "",
        optionScore: undefined,
      };
      // if (this.form.questionType == 10) {
        this.form.optionList.push(data);
      // } else if (this.form.questionType == 20) {
      //   this.form.optionListall.push(data);
      // }

      this.$forceUpdate();
    },
    // docancle() {
    //   this.centerDialogVisible = false;
    //   this.optionGroupId = ''
    //   editors.destroy()
    //   this.empty();
    // },
    empty() {
      this.form = {
        optionGroupId: '',
        groupName: '',
        optionList: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
        optionListall: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
      };
      this.$refs["form"].resetFields();
      // editors.txt.clear();
    },
    // closeDialog() {
    //   this.centerDialogVisible = false;
    //   editors.destroy();
    //   this.empty();
    // },
    // formTypeChange(value) {
    //   this.$refs["form"].resetFields();
    //   // editors.txt.clear();
    //   this.form = {
    //     groupName: value,
    //     optionList: [
    //       {
    //         optionName: "",
    //         optionImgUrl: "",
    //         optionImg: "",
    //         optionScore: undefined,
    //       },
    //       {
    //         optionName: "",
    //         optionImgUrl: "",
    //         optionImg: "",
    //         optionScore: undefined,
    //       },
    //     ],
    //     optionListall: [
    //       {
    //         optionName: "",
    //         optionImgUrl: "",
    //         optionImg: "",
    //         optionScore: undefined,
    //       },
    //       {
    //         optionName: "",
    //         optionImgUrl: "",
    //         optionImg: "",
    //         optionScore: undefined,
    //       },
    //       {
    //         optionName: "",
    //         optionImgUrl: "",
    //         optionImg: "",
    //         optionScore: undefined,
    //       },
    //       {
    //         optionName: "",
    //         optionImgUrl: "",
    //         optionImg: "",
    //         optionScore: undefined,
    //       },
    //     ],
    //   };
    // },
    //图片上传
    handleAvatarSuccess2(res, fiie, index) {
      let extension = res.name.substring(
          res.name.lastIndexOf(".") + 1,
          res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "CT_QUESTION_BANK");
      formData.append("isPublic ", true);
      const that = this;
      that
          .$Postformat("/sys/file/upload", formData, 3000, true, 6)
          .then((result) => {
            that.form.optionList[index].optionImg = result.data.fileKey || "";
            that.form.optionList[index].optionImgUrl = result.data.fileUrl || "";
          })
          .catch(() => {
            setTimeout(() => {
              that.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
    },
    // //图片上传
    // handleAvatarSuccess3(res, fiie, index) {
    //   let extension = res.name.substring(
    //       res.name.lastIndexOf(".") + 1,
    //       res.name.length
    //   );
    //   let formData = new FormData();
    //   formData.append("file ", res.raw);
    //   formData.append("fileType ", extension);
    //   formData.append("folder ", "LECTURER");
    //   formData.append("isPublic ", true);
    //   const that = this;
    //   that
    //       .$Postformat("/sys/upload", formData)
    //       .then((result) => {
    //         that.form.optionListall[index].optionImg = result.data.fileKey || "";
    //         that.form.optionListall[index].optionImgUrl = result.data.fileURL || "";
    //       })
    //       .catch(() => {
    //         setTimeout(() => {
    //           that.$message({
    //             type: "warning",
    //             message: "上传失败",
    //           });
    //         }, 300);
    //       });
    // },
    //获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if(this.groupName) {
        params.groupName = this.groupName
      }
      this.doFetch({
        url: "/biz/hr/evaluate/option-group/pageList",
        params,
        pageNum,
      },true,6);
    },


    doClose() {

      this.centerDialogVisible = false;
      this.$refs["form"].resetFields();
      this.form = {
        optionGroupId: "", // 选项组Id
        groupName: "", // 选项组名称
      };
      this.empty();
    },
    //启用禁用切换
    onStatusChange(row){
      this.$post("/biz/hr/evaluate/option-group/enabled", {
        optionGroupId: row.optionGroupId,
      },3000,true,6).then((ret) => {
        this.$message({
          type: "success",
          message: '操作成功！',
        });
        this.getData(-1);
      });
    },
    // 删除
    onDelete(row) {
      this.$confirm("此操作将永久删除该选项组, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
          .then(() => {
            this.$post("/biz/hr/evaluate/option-group/delete", {
              optionGroupId: row.optionGroupId,
            },3000,true,6).then((ret) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData(-1);
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
.el-form-questionType/deep/.el-form-item__content {
  margin-left: 0 !important;
}
.w-e-text-container {
  height: 250px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
</style>
<style lang="less" scoped>
.el-form-questionType {
  display: flex;
}
.el-form-item__content {
  display: flex;
  align-items: center;
  .el-radio-group .el-form-item /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .checkbox /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
    .el-checkbox {
      display: flex;
      align-items: center;
      .el-checkbox__label {
        display: flex;
        align-items: center;
        .el-input {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
.delet {
  margin-left: 0.5rem;
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 60px;
  height: 60px !important;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
